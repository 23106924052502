"use client";
import {useState} from "react";
import {Button} from "./ui/button";
import axios from "axios";

type Props = {isPro: boolean; styling: string};

const SubscriptionButton = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const handleSubscription = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/api/stripe");
      window.location.href = response.data.url;
    } catch (error) {
      console.error(
        "Something went wrong while trying to contact /api/stripe from handleSubscription: ",
        error
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <Button
      className={props.styling}
      disabled={loading}
      onClick={handleSubscription}
      variant="outline"
    >
      {props.isPro ? "Manage Subscriptions" : "Upgrade to Pro"}
    </Button>
  );
};

export default SubscriptionButton;
