import {type ClassValue, clsx} from "clsx";
import {twMerge} from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getS3Url(file_key: string) {
  const url = `https://${process.env.NEXT_PUBLIC_S3_BUCKET_NAME}.s3.${process.env.AWS_REGION}.amazonaws.com/${file_key}`;
  return url;
}

export function convertToAscii(inputString: string) {
  //* remove none ascii characters and replace them with an empty string
  const asciiString = inputString.replace(/[^\x00-\x7F]/g, "");
  return asciiString;
}
