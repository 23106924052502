"use client";
import {useState} from "react";
import {useDropzone} from "react-dropzone";
import {CloudUpload, Loader2} from "lucide-react";
import {auth} from "@clerk/nextjs";
import {toast} from "react-hot-toast";
import {uploadPDFToS3Buckets} from "@/actions/awsStorageActions";
//import createChat from "@/actions/createChat";
import {Toaster} from "react-hot-toast";
import {useRouter} from "next/navigation";
import {useMutation} from "@tanstack/react-query";
import axios from "axios";
//import AutoSignIn from "./clerk/autosignin";
// TODO: Add Loading capability to the code

const FileUpload = ({shownText, userId}: any) => {
  shownText =
    shownText ||
    "Start by clicking here to add your PDF File or just drag-and-drop it!";
  const router = useRouter();
  const [altAcceptedFiles, setAltAcceptedFiles] = useState<File[] | undefined>(
    undefined
  );
  const [uploading, setUploading] = useState(false);
  const {mutate, isPending} = useMutation({
    mutationFn: async ({
      file_key,
      file_name,
    }: {
      file_key: string;
      file_name: string;
    }) => {
      const response = await axios.post("/api/create-chat", {
        file_key,
        file_name,
      });
      return response.data;
    },
  });

  const onDrop = async (acceptedFiles: any) => {
    const file =
      altAcceptedFiles && altAcceptedFiles.length > 0
        ? altAcceptedFiles[0]
        : acceptedFiles[0];

    if (file.size > 10 * 1024 * 1024) {
      toast.error("File too large");
      return;
    }
    try {
      setUploading(true);
      const formData = new FormData();
      formData.append("file", file);
      const receivedData: any | undefined =
        await uploadPDFToS3Buckets(formData);
      console.log("RECEIVED DATA: -->>>", receivedData);
      if (!receivedData?.file_key || !receivedData.file_name) {
        toast.error("Something went wrong");
        return;
      }
      mutate(receivedData, {
        onSuccess: ({chat_id}) => {
          toast.success(`Chat created!: ${chat_id}`);
          router.push(`/chat/${chat_id}`);
        },
        onError: (error) => {
          toast.error("something went wrong");
          console.error("Upload failed:", error);
        },
      });
    } catch (error) {
      console.error("Upload failed:", error);
      toast.error("Upload failed");
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const filesArr = [] as File[];
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files.item(i);
      if (!file) continue;
      filesArr.push(file);
    }
    return setAltAcceptedFiles(filesArr);
  };

  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
    onDrop: onDrop,
    disabled: uploading || isPending || !userId ? true : false,
  });

  return (
    <section className="p-2 bg-white rounded-xl">
      <div
        {...getRootProps({
          className: `hidden sm:flex overflow-hidden max-w-full max-h-full border-dashed border-2 rounded-xl cursor-pointer bg-gray-50 py-8 justify-center items-center flex-col bg-gradient-to-r from-black-400 dark:via-accentDarkAlaa dark:via-accentAlaa to-white-500 relative`,
        })}
      >
        <input
          {...getInputProps()}
          // Apply additional styling for disabled state
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
        />
        <br />
        {uploading || isPending ? (
          <>
            {/* loading state */}
            <Loader2 className="h-10 w-10 text-blue-500 animate-spin" />
            <p className="mt-2 text-sm text-slate-400 dark:text-dark">
              Contacting GPT and wait for an answer
            </p>
          </>
        ) : (
          <div className="bg-white p-5 rounded-lg flex justify-center content-center">
            <CloudUpload className="w-10 h-10 text-blue-500 mr-5" />
            <p className="mt-2 text-xs sm:text-sm text-slate-400">
              {shownText}
            </p>
          </div>
        )}
      </div>
      <div
        {...getRootProps({
          className: `sm:hidden overflow-hidden max-w-full max-h-full border-dashed border-2 rounded-xl cursor-pointer bg-gray-50 py-8 justify-center items-center flex flex-col bg-gradient-to-r from-black-400 dark:via-accentDarkAlaa dark:via-accentAlaa to-white-500 relative`,
        })}
      >
        <input
          type="file"
          onChange={handleChange}
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
        />

        <br />
        {uploading || isPending ? (
          <>
            {/* loading state */}
            <Loader2 className="h-10 w-10 text-blue-500 animate-spin" />
            <p className="mt-2 text-sm text-slate-400 dark:text-dark">
              Contacting GPT and wait for an answer
            </p>
          </>
        ) : (
          <div className="relative bg-white p-3 sm:p-5 rounded-lg flex justify-center content-center">
            <CloudUpload className="w-10 h-10 text-blue-500 mr-5" />
            <p className="sm:mt-2 text-xs sm:text-sm text-slate-400">
              {shownText}
            </p>
          </div>
        )}
      </div>
      <Toaster />
    </section>
  );
};

export default FileUpload;
